import { createContext, useEffect, useReducer } from "react";

// Define initial state
const INITIAL_STATE = {
  varifyCode: JSON.parse(localStorage.getItem("varifyCode")) || null,
  error: null,
};

// Create the context
export const VarifyContext = createContext(INITIAL_STATE);
// Define reducer
const VarifyReducer = (state, action) => {


  switch (action.type) {
    case "ADD_VARIFY":
      return {
        varifyCode: action.payload,
      };
    case "ERROR":
      return {
        error: action.payload,
      };
    default:
      return state;
  }
};



// Create the context provider component
export const VarifyContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(VarifyReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("varifyCode", JSON.stringify(state.varifyCode));
  }, [state.varifyCode]);

  return (
    <VarifyContext.Provider
      value={{
        varifyCode: state.varifyCode,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </VarifyContext.Provider>
  );
};
