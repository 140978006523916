import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import backimg from "../../img/xl_hero.jpg";
import ddfCode from "./DDF.js";
import axios from "axios";
import { FaCheckCircle } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import "./XlPromos.scss";
import { VarifyContext } from "../../context/VarifyContext.js";

const XlPromos = () => {
  const { coupon, promo } = useParams();
  const [isValid, setValid] = useState(false);
  const { dispatch } = useContext(VarifyContext);

  const [qrCode, setQrCode] = useState(coupon || "");
  const [link, setLink] = useState(promo || "");

  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState(null);
  const [qrData, setQrData] = useState("");
  const [scannedData, setScannedData] = useState("");
  const [splitData, setSplitData] = useState("");

  const [varifyCode, setVarifyCode] = useState(null);
  const [loading, setLoading] = useState(false);

  // const apiLink = "http://localhost:4348/api/v1";
  const apiLink = "https://xl-api.notionspromos.com/api/v1";

  // console.log(qrCode);



  useEffect(() => {
    if (!qrCode || !link) {
      return;
    }

    const getVarifyCode = async () => {
      setLoading(true);
      try {
        await axios.get(`${apiLink}/varifyCode/${qrCode}`).then((response) => {
          const isCodeValid = response.data.success;
          dispatch({ type: "ADD_VARIFY", payload: isCodeValid }); 
          setApiResponse(isCodeValid); 
        });
      } catch (error) {
        setError("Code not valid or Already Used");
      } finally {
        setLoading(false);
      }
    };

    getVarifyCode();
  }, [qrCode, link]); 

  
  const statusCode = () => {
    if (apiResponse === true) {
      return <FaCheckCircle color="#39e75f" />;
    }
    if (apiResponse === false) {
      return <RxCrossCircled color="#ed4337" />;
    }
    if (apiResponse === null) {
      return <p> - </p>;
    }
    if(loading){
      return <p> L </p>
    }
    return null; // This ensures the function always returns something, even if no conditions are met
  };

  //  console.log(statusCode());

  const navigate = useNavigate();

  // console.log(qrCode, link);

  useEffect(() => {
    if (!qrCode || !link) {
      return;
    }
    if (apiResponse === true) {
     
      if (link === "3") {
        navigate(`/xl/jocker/${qrCode}`, { replace: true });
      }
      localStorage.setItem("coupon", qrCode);
    } else {
      setError("Code not valid or Already Used");
    }
  }, [coupon, promo, apiResponse, qrCode, link]);

  const hadlleSubmit = (e) => {
    e.preventDefault();
    if (apiResponse === true) {
      
      if (link === "3") {
        navigate(`/xl/jocker/${qrCode}`, { replace: true });
      }
     
    } else {
      setError("Code not valid or Already Used");
    }
    // setQrCode("");
    // setLink("");
  };

 useEffect(() => {
   const getLocalCode = localStorage.getItem("coupon");
   if (qrCode !== getLocalCode) {
       localStorage.removeItem("chancesLeft");
       localStorage.removeItem("LocalWinItem");
   }
 }, [qrCode]);

  return (
    <div
      className="xl__home"
      style={{
        backgroundImage: `url(${backimg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        objectFit: "contain",
        height: "80vh",
      }}
    >
      <div className="xl__home__wrap">
        <div className="xl__coupen__form">
          <div className="logo" >
            <img src={require("../../img/logo.png")} alt="" />
          </div>
          <div class="xl__brand__title">
            <h1>Enter The Code and Win</h1>
          </div>

          <form className="__form">
            {/* <useQrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%" }}
            /> */}
            <inputs>
              {/* <input
                type="text"
                placeholder="CDXY"
                maxLength="4"
                required
                id="code_one"
                value={code_one}
                onChange={(e) => setCode_one(e.target.value)}
              />*/}

              <input
                type="text"
                placeholder="DFERSK56HGD"
                maxLength="13"
                required
                id="code_two"
                value={qrCode}
                onChange={(e) => setQrCode(e.target.value)}
              />
              <div className="__space">{statusCode()}</div>
              <input
                value={link}
                onChange={(e) => setLink(e.target.value)}
                required
                type="text"
                placeholder="1"
                maxLength="8"
              />
              {/* <div className="__space">
                <p>-</p>
              </div> */}
            </inputs>
            {/* <QrGen/> */}
            {/* <button type="submit">ENTER</button> */}
            {/* <div className="err_msg">
              {error && <p>{error}</p>}

            </div> */}
            {apiResponse === false && (
              <div className="error_msg">
                <p>
                  <em>⚠️ Oops! The code you entered is incorrect. </em>
                  <br /> Please try again.
                </p>
              </div>
            )}
          </form>
          {/* <div>
            <QRCodeScanner onScanSuccess={handleScanSuccess} />
            {scannedData && (
              <div>
                <h2>Scanned Data:</h2>
                <p>{scannedData}</p>
              </div>
            )}
          </div> */}
        </div>
      </div>

      {/* <div className="xl__home__wrap">
        <div className="logo">
          <img src={require("../../img/xl.webp")} alt="" />
        </div>
        <Link to={"/xl"}>
          <button>EXPLORER</button>
        </Link>
      </div> */}
    </div>
  );
};

export default XlPromos;
