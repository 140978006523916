import React, { useContext, useEffect, useState } from "react";
import { FaStar } from "react-icons/fa6";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext.js";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../components/navBar/NavBar.jsx";
import Com_Login from "../../components/login/Com_Login.jsx";
import Com_Register from "../../components/login/Com_Register.jsx";
import Rank_Register from "../../components/login/Rank_Register.jsx";
import { IoIosCloseCircleOutline } from "react-icons/io";

const XlPlus = () => {
  const [data, setData] = useState([]);
  const { user, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showBtn, setShowBtn] = useState(false);
  const [winItem, setWinItem] = useState("");
  

  const [test,setTest] = useState([{name:"",number:""}])

  const devApiLink = "http://localhost:4348/api/v1";
  const apiLink = "https://xl-api.notionspromos.com/api/v1";

  const buyersName = user ? user.details.name : "";
  const buyerCode = user ? user.details.code : "";

  console.log(test);

  const handChange =(i,e)=>{
    e.preventDefault();
    const values = [...test]
    values[i][e.target.name] = [e.target.value]
    setTest(values)
  }
  
  const handleAddFields =(e)=>{
    e.preventDefault()
    setTest([...test,{name:"",number:""}])
  }

  const handleRemoveFields =(i)=>{
    const values = [...test]
    values.splice(i,1)
    setTest(values)
  }


  const handleShowBtn = () => {
    setShowBtn(!showBtn);
  };

  const closeWindow = () => {
    setWinItem("");
  };

  // const buyersName = "SFI-C02052";

  const logoutUser = async () => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      await axios.get(`${apiLink}/logout`);
      localStorage.removeItem("user");
      dispatch({ type: "LOGOUT" });
      navigate("/xl");
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching data:", error);
      }
    }
    return () => controller.abort();
  };

  const getBuyersData = () => {
    const getCustomerdata = data.filter(
      (item) => item.Customer_No === buyerCode
    );
    return getCustomerdata[0]; // Return the first item in the filtered array
  };

  const buyerData = getBuyersData();

  // Find the index of the current user
  const buyerIndex = data.findIndex((item) => item.Customer_No === buyerCode);

  // Ensure at least the first three customers are visible
  const startIndex = Math.max(0, buyerIndex - 2);
  const endIndex = Math.min(data.length, buyerIndex + 3);

  // Display the first three customers if they are not already included
  const firstThree = data.slice(0, 3);
  const surroundingData = data.slice(startIndex, endIndex);

  // Combine the arrays while removing duplicates
  // const combinedData = Array.from(new Set([...firstThree, ...surroundingData]));
  const combinedData = Array.from(new Set([...surroundingData]));

  useEffect(() => {
    if (!user) {
      return;
    }
    axios
      .post(`${apiLink}/proxy/get-data`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user]);

  const getValue = buyerData?.Line_Amount;

  const calPoints = () => {
    const points = getValue / 1500;
    return points.toFixed(0);
  };

  // console.log(calPoints());

  return (
    <>
      {user ? (
        <>
          <div className="__max_w">
            <div className="__min_w">
              <div className="xl_wrp">
                <div className="xl_nav">
                  <div className="xl_sec_left">
                    <img src={require("../../img/plusLogo.jpg")} alt="" />
                  </div>
                  <div className="xl_sec_right">
                    <div className="xl_sec_in">
                      <span>
                        Welcome <em>{buyerData?.Customer_No}</em>
                      </span>
                    </div>
                    <div className="xl_sec_in">
                      <div className="pro_imgs">
                        <img src={require("../../img/proImg.jpg")} alt="" />
                      </div>
                    </div>
                    <div className="xl_sec_in">
                      <span>
                        Your Points <em>{calPoints()}</em>{" "}
                      </span>
                      {/* <div>
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                      </div> */}
                    </div>
                    <div className="xl_sec_in">
                      <span className="__button" onClick={logoutUser}>
                        Logout
                      </span>
                    </div>
                  </div>
                </div>
                <NavBar />
                <div className="xl_wall">
                  <img src={require("../../img/plus_banner.jpg")} alt="" />
                </div>
                <div className="rank">
                  <div className="rank_img">
                    <div className="rank_img_se">
                      <img src={require("../../img/proImg.jpg")} alt="" />
                    </div>
                    <div className="rank_img_info">
                      <h2>
                        Welcome, <br /> <em>{buyerData?.Customer_Name}</em>{" "}
                      </h2>
                      <p>
                        {" "}
                        We hope you are enjoing Xl <br /> Climb the Ranks
                      </p>
                    </div>
                  </div>
                  <div className="rank_info">
                    <div className="rank_info_img">
                      <img src={require("../../img/symb.png")} alt="" />
                    </div>
                    <div className="rank_info_star">
                      <h2>YOUR POINTS</h2>
                    </div>
                    <div className="rank_info_count">
                      <small>{buyerData?.Line_Amount}</small>
                      <h1>{calPoints()}</h1>
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: "gray", height: "200px" }}>
                  {test.map((i, item) => (
                    <div
                      style={{ display: "flex", flexDirection: "column" }}
                      key={i}
                    >
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={item.name}
                        onChange={(e) => handChange(item, e)}
                      />
                      <input
                        type="text"
                        name="number"
                        placeholder="Number"
                        value={item.number}
                        onChange={(e) => handChange(item, e)}
                      />
                      <span onClick={handleRemoveFields}> Delete </span>
                    </div>
                  ))}
                  <button className="button" onClick={handleAddFields}>
                    Add Item
                  </button>
                </div>

                <div className="gif_sec">
                  <h2>SHOP REWARDS NOW</h2>
                  <div className="img_main_wrp">
                    <div className="img_wrp">
                      <img src={require("../../img/Gift_img-02.webp")} alt="" />
                      <h3>Distribution van</h3>
                    </div>
                    <div className="img_wrp">
                      <img src={require("../../img/Gift_img-03.webp")} alt="" />
                      <h3>Rolex Watch </h3>
                    </div>
                    <div className="img_wrp">
                      <img src={require("../../img/Gift_img-01.webp")} alt="" />
                      <h3>Business Support Tools</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="over_login">
          <div className="form_wrp">
            <div className="close">
              <Link to={"/xl"}>
                <IoIosCloseCircleOutline color="white" size={18} />
              </Link>
            </div>
            <div className="form_wrp_head">
              {!showBtn ? <strong>Login</strong> : <strong>Register</strong>}
            </div>

            {!showBtn ? <Com_Login /> : <Rank_Register />}
            {/* <Com_Login /> */}

            {!showBtn && (
              <small>
                {" "}
                If you dont have account{" "}
                <em onClick={handleShowBtn}>register</em>{" "}
              </small>
            )}
            {showBtn && (
              <small>
                {" "}
                If you have account <em onClick={handleShowBtn}>Login</em>{" "}
              </small>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default XlPlus;
