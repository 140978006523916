import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";
import { VarifyContextProvider } from './context/VarifyContext.js';
// import reportWebVitals from "./reportWebVitals.js";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <VarifyContextProvider>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </VarifyContextProvider>
  </React.StrictMode>
);
// Register the service worker
serviceWorkerRegistration.register();

